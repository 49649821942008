<template>
  <v-row justify="center" align="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined height="65px"
            rounded v-model="searchKeyword" @click="expand = !expand" :append-icon="expand ? '' : 'mdi-magnify'"
            :append-outer-icon="expand ? 'mdi-keyboard-off-outline' : ''" @click:append-outer="expand = false"
            @click:append="searchKeyword = ''">
          </v-text-field>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center">
      <v-card v-if="expand || searchKeyword !== ''" :ripple="false" class="mt-2 containerBorder overflow-y-auto"
        width="95%" flat :height="cardHeight" @click="expand = false">
        <v-card-text v-if="matchingForms.length === 0">
          <span class="text-h4">
            No matching forms found.
          </span>
        </v-card-text>
        <v-row v-else class="pa-5" no-gutters>
          <v-col v-for="item in matchingForms" :key="item.id"
            @click="viewDoc(item.formPdf, item.formName, 'form', item.pdfUrl)" class="pa-3" cols="4">
            <v-card class="PDFborder" height="75" flat>
              <v-row no-gutters class="fill-height" align="center">
                <v-col cols="2">
                  <v-img src="@/assets/pdf.png" contain height="50"></v-img>
                </v-col>
                <v-col cols="10" align="start">
                  <span class="text-h6">
                    {{ item.formName | trimLength }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-1" flat width="95%" v-else>
        <v-row>
          <v-col cols="3">
            <v-card flat width="100%" :height="cardHeight" class="containerBorder pa-0 ma-0" color="primary">

              <!-- The below is used to display the Form Packets. It is same for English and Spanish Version.  -->

              <v-btn height="45" width="90%" class="my-3"
                @click="$store.commit('setSelectedFormCat', { category: 'Packets' })"
                v-bind:color="getSelectedFormCat.category === 'Packets' ? '#15306B' : 'white'"
                v-bind:style="{ 'color': getSelectedFormCat.category === 'Packets' ? 'white' : 'black' }">{{
                  $t('packets')
                }}</v-btn>

              <!-- The below is used to display the English Form. The below button is only visible in Spanish Mode.  -->

              <v-btn height="45" width="90%" @click="$store.commit('setSelectedFormCat', { category: 'English' })"
                class="mb-3" v-if="getCurrentLanguage === 'es-US'"
                v-bind:color="getSelectedFormCat.category === 'English' ? '#15306B' : 'white'"
                v-bind:style="{ 'color': getSelectedFormCat.category === 'English' ? 'white' : 'black' }">
                {{ $t('englishForms') }}</v-btn>

              <!-- The below is used to display the All Form. It lists all the forms.  -->

              <v-btn height="45" width="90%" @click="$store.commit('setSelectedFormCat', { category: 'All' })"
                class="mb-3" v-bind:color="getSelectedFormCat.category === 'All' ? '#15306B' : 'white'"
                v-bind:style="{ 'color': getSelectedFormCat.category === 'All' ? 'white' : 'black' }">{{
                  $t('allForms')
                }}</v-btn>

              <v-list :height="getFormCatListHeight" class="overflow-y-auto">
                <v-list-item v-for="(cat, i) in $t('formCategory')" :key="i">
                  <v-list-item-content>
                    <v-btn height="45" @click="$store.commit('setSelectedFormCat', { category: cat.categoryType })"
                      v-bind:color="getSelectedFormCat.category === cat.categoryType ? '#15306B' : 'primary'"
                      class="white--text">
                      {{ cat.categoryName }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-card width="95%" class="containerBorder overflow-y-auto" :height="cardHeight">
              <v-row class="ma-3" v-if="getSelectedFormCat.category !== 'Packets'">
                <v-col v-for="(item, i) in getForms" :key="i" cols="4">
                  <v-card class="PDFborder" height="80" flat
                    @click="viewDoc(item.formPdf, item.formName, 'form', item.pdfUrl)">
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-img src="@/assets/pdf.png" contain height="50"></v-img>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="text-h6">
                          {{ item.formName | trimLength }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!-- To display form Packets -->
              <v-row v-else class="ma-3">
                <v-col v-for="(item, i) in getForms" :key="i" cols="4">
                  <v-card class="PDFborder" height="80" flat
                    @click="viewDoc(item.packetFiles, item.packetName, 'packet', item.packetLink)">
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-img src="@/assets/pdf.png" contain height="50"></v-img>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="text-h6">
                          {{ item.packetName | trimLength }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex'
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: 'forms',
  data() {
    return {
      tab: 0,
      searchKeyword: '',
      searchBarIcon: 'mdi-magnify',
      expand: false,
      cardHeight: 650,
      folderName: '',
      folderDisplayName: '',
      src: '',
      openFolder: false,
    }
  },
  components: {
    SimpleKeyboard
  },
  computed: {
    ...mapGetters(['getFormCategory', 'getQnaKeyword', 'getCurrentLanguage', 'getSelectedFormCat', 'getFormCatListHeight', 'getFormsEn']),
    getForms() {
      let temp = []

      if (this.getSelectedFormCat.category === 'All') {

        this.$i18n.t("forms").forEach(form => {
          temp.push(form)
        })

      } else if (this.getSelectedFormCat.category === 'Packets') {

        temp = this.$i18n.t("formPackets").values()

      }
      else if (this.getSelectedFormCat.category === 'English') {

        // This is special case, no interationalization used. English forms to be shown in spanish version
        this.getFormsEn.forEach(form => {
          temp.push(form)
        })

      }
      else {

        this.$i18n.t("forms").forEach(form => {
          if (form.formCategory.toLowerCase().includes(this.getSelectedFormCat.category.toLowerCase())) {
            temp.push(form)
          }
        })

      }
      return temp
    },
    matchingForms() {
      let temp = []
      this.$i18n.t("forms").forEach(form => {
        if (form.formName.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
          temp.push(form)
        }
      })
      return temp
    }
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = 'mdi-close-circle'
        this.cardHeight = 380;
      } else {
        this.searchBarIcon = 'mdi-magnify'
        this.cardHeight = 650
      }
    },
    getQnaKeyword(newVal) {
      this.tab = 0
      this.$i18n.t("formCategory").forEach(category => {
        if (category.categoryName.toLowerCase() === newVal) {
          this.displayFolderContent(category.categoryType, category.categoryName)
        }
      })
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    viewDoc(pdfUrl, fileName, fileType, emailLink) {
      console.log('Cached Pdf Url: ', pdfUrl)
      console.log('Cached Pdf Name: ', fileName)
      console.log('Cached Pdf Type: ', fileType)
      console.log('Cached Pdf link: ', emailLink)

      this.$store.dispatch('openPdfViewer', {
        url: pdfUrl,
        name: fileName,
        link: emailLink,
        type: fileType
      })

      // Touch Usage
      this.$store.commit('setTouchRequest', {
        module: 'Forms',
        action: fileType,
        response: fileName,
        timeStamp: new Date(),
        requestType: 'Touch'
      })
    },
    hideFolderContent() {
      this.openFolder = false;
      this.folderName = '';
      this.folderDisplayName = '';
      this.expand = false;
    },
    displayFolderContent(formCategory, categoryType) {
      this.openFolder = true;
      this.folderName = formCategory;
      this.folderDisplayName = categoryType
      // this.category = formCategory
      console.log(categoryType)
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 55) {
        return val
      }
      return `${val.substring(0, 40)}...`
    }
  },
  mounted() {
    this.tab = 0
    this.$i18n.t("formCategory").forEach(category => {
      if (category.categoryName.toLowerCase() === this.getQnaKeyword) {
        this.displayFolderContent(category.categoryType.toLowerCase(), category.categoryName)
      }
    })
    console.log('Selected Form Category: ', this.getSelectedFormCat)
  }

}
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}

.selectedTextColor {
  color: white
}

.notselectedTextColor {
  color: black
}
</style>

<i18n>
  {
    "en": {
        "packets": "Form Packets",
        "englishForms": "English Forms",
        "allForms": "All Forms",
        "placeHolder": "Enter name/number to search for forms"
      },
    "es":{
        "packets": "Paquetes de Formulario",
        "englishForms": "Formularios Ingleses",
        "allForms": "Todas las Formas",
        "placeHolder": "Ingrese nombre/número para buscar formularios"
      }
  }
</i18n>
