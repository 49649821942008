<template>
<v-row    
    align="center">
    <v-col cols="12" justify="center" align="center">
        <iframe width="90%" height="700" src="https://jurorportal.nmcourts.gov/qualification/" title="New Mexico Juror Portal">
</iframe>
    </v-col>
    
</v-row>
</template>

<script>
export default {
    name: 'juryCheckIn',
    data(){
        return{            
        }
    },
    computed:{
        
    },
    methods:{
      
    },
    filters:{
        
    },
    mounted(){
        
    }

}
</script>
